<template>
  <div>
    <v-sheet style="border-radius: 20px; padding: 20px; margin-bottom: 20px;">
      <h4 class="title">Доска задач</h4>
    </v-sheet>
    <div style="height: 500px"  v-if="loading">
      <v-progress-circular class="mt-5 mx-auto" color="primary" indeterminate width="2"/>
    </div>
    <Board :can_create="project.permissions.can_add_tasks" context="project"
           v-else-if="project.permissions.can_see_tasks" :task-gen="load"
           :tasks="$store.state.projects.currentProjectTasks"/>
  </div>

</template>

<script>
import Board from "@/components/Tasks/Board.vue";
import analytics from "@/api/analytics.js";

export default {
  name: "ProjectsItemTrelloComponent",
  components: {Board},
  data() {
    return {
      loading: true,
    }
  },
  computed: {
    project() {
      return this.$store.state.projects.currentProject;
    }
  },
  methods: {
    load() {
      this.$store.dispatch('getCurrentProjectTasks', {id: this.$store.state.projects.currentProject.id}).then(() => {
        this.loading = false;
        this.$store.commit('setTasks', this.$store.state.projects.currentProjectTasks);
      });
    }
  },
  mounted() {
    if (!this.$store.state.projects.currentProject.permissions.can_see_tasks) {
      this.$router.back()
      return;
    }
    analytics.openProjectTasksEvent(this.$route.params.project_id, this.$store.state.user.currentUser.id);
    this.load();
  }
}
</script>

<style scoped>

</style>
